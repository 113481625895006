import React, { memo, useEffect, useRef } from 'react'
import Form from 'components/Form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import BookingModel from 'models/Booking'
import CustomPhoneInput from 'components/CustomPhoneInput'
import CustomSelect from 'components/CustomSelect'
import { bookingStatuses } from 'constants/bookings'
import defaults from './defaults'
import yupHelper from 'utils/yupHelper'
import getProps from 'utils/getProps'
import { fields, defaults as fieldDefaults } from 'utils/fields'
import SlotTimetable from './SlotTimetable'
import deepmerge from 'deepmerge'

const BookingForm = memo(
  ({
    title = 'Enter Details',
    slotTimetable,
    onSubmit,
    onDiscard,
    showStatus = false,
    showSlotSelector = false,
    submitButtonText = 'Book',
    dirtyOnStart = true,
    defaultValues = defaults,
    cardStyle,
    customer = null,
    loadingAfterSubmitted = false,
    editable,
    settings,
    selectorRef,
    showSubTitle,
  }) => {
    let customerDefaults = deepmerge(fieldDefaults, defaultValues)
    if (customer) {
      customerDefaults = deepmerge(customerDefaults, customer)
    }

    const isInitialMount = useRef()
    const hookForm = useForm({
      resolver: yupResolver(BookingModel.validationSchema.shape(...fields.map((field) => yupHelper(field)))),
      defaultValues: customerDefaults,
    })
    const {
      formState: { errors },
      control,
      register,
      reset,
    } = hookForm

    useEffect(() => {
      // if (isInitialMount.current) {
      //   isInitialMount.current = false
      // } else {
      reset(customerDefaults)
      // }
    }, [defaultValues, customer])

    return (
      <Form
        title={title}
        subTitle={showSubTitle && slotTimetable?.displayTime()}
        submitButtonText={submitButtonText}
        dirtyOnStart={dirtyOnStart}
        hookForm={hookForm}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        cardStyle={cardStyle}
        defaultValues={customerDefaults}
        loadingAfterSubmitted={loadingAfterSubmitted}
      >
        {showSlotSelector && settings && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Controller
                control={control}
                name="slotTimetable"
                render={({ field }) => (
                  <SlotTimetable
                    {...field}
                    settings={settings}
                    defaultDate={slotTimetable.start}
                    slotId={slotTimetable.slotId}
                    selectedSlotTimetable={slotTimetable}
                    selectorRef={selectorRef}
                  />
                )}
              />
            </GridItem>
          </GridContainer>
        )}
        {showStatus ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <CustomSelect {...field} label="Status" options={bookingStatuses} error={errors.status} fullWidth />
                )}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <input type="hidden" {...register('status')} />
        )}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <CustomInput {...field} label="Name" error={errors.name} disabled={!editable} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <CustomInput
                  {...field}
                  type="email"
                  label="Email"
                  error={errors.email || errors.emailOrPhone}
                  disabled={!editable}
                  fullWidth
                />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              fullWidth={true}
              name="phone"
              render={({ field }) => (
                <CustomPhoneInput
                  {...field}
                  type="phone"
                  label="Phone Number"
                  error={errors.phone || errors.emailOrPhone}
                  disabled={!editable}
                  fullWidth
                />
              )}
            />
          </GridItem>
        </GridContainer>
        {fields.map(({ name, ...props }) => (
          <GridContainer key={name}>
            <GridItem xs={12} sm={12} md={12}>
              <Controller
                control={control}
                name={name}
                render={({ field }) => (
                  <CustomInput {...field} {...props} error={getProps(errors, name)} disabled={!editable} fullWidth />
                )}
              />
            </GridItem>
          </GridContainer>
        ))}
      </Form>
    )
  }
)

export default BookingForm
