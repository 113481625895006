import React, { useEffect, memo, useRef } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'
import ConfirmDialog from 'components/ConfirmDialog'
import { useLoading } from 'hooks/useLoading'
import { past, adjective } from 'utils/tense'
import useStyles from './styles'
import { useSnackbar } from 'notistack'

const Form = memo(
  ({
    dirtyOnStart = false,
    hookForm,
    submitButtonText = 'Save',
    submitConfirmTitle = 'Confirm',
    submitConfirmText = 'Are you sure want to save?',
    discardButton = true,
    discardButtonText = 'Discard',
    discardConfirmTitle = 'Discard',
    discardConfirmText = 'Are you sure want to discard the changes?',
    loadingAfterSubmitted,
    onSubmit,
    onDiscard,
    title,
    subTitle,
    children,
    cardStyle,
    defaultValues,
    headerColor = 'primary',
    logo: Logo,
  }) => {
    const [, setLoading] = useLoading()
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles()
    const submitDialogRef = useRef(null)
    const discardDialogRef = useRef(null)

    const {
      handleSubmit,
      trigger,
      getValues,
      setError,
      reset,
      formState: { isDirty, isSubmitting, isSubmitSuccessful, isSubmitted, errors },
    } = hookForm

    useEffect(() => {
      if (isSubmitSuccessful) {
        if (Object.keys(errors).length === 0) {
          reset(getValues())
          enqueueSnackbar(`${past(submitButtonText)} successfully!`, { variant: 'success' })
        }
      }
    }, [isSubmitSuccessful, errors])

    useEffect(() => {
      if (isSubmitted) {
        if (!loadingAfterSubmitted) {
          setLoading(false)
        }
        if (Object.keys(errors).length > 0) {
          enqueueSnackbar(`Error while ${adjective(submitButtonText)}!`, { variant: 'error' })
          if (errors?.form) {
            enqueueSnackbar(errors?.form?.message, { variant: 'error' })
          }
          setLoading(false)
        }
      }
    }, [isSubmitted])

    useEffect(() => {
      if (isSubmitting) {
        setLoading(true)
      }
    }, [isSubmitting])

    onDiscard = onDiscard ?? (() => reset(defaultValues))

    const onSubmitForm = async (e) => {
      e.preventDefault()
      if (await trigger()) {
        submitDialogRef.current.open()
      }
    }

    const onConfirmSubmit = () => {
      submitDialogRef.current.close()
      handleSubmit(async (data) => {
        try {
          await onSubmit(data)
        } catch (e) {
          console.error(e)
          setError('form', {
            type: 'manual',
            message: e.message,
          })
        }
      })()
    }

    const onDiscardForm = () => {
      if (isDirty) {
        discardDialogRef.current.open()
      } else {
        onConfirmDiscard()
      }
    }

    const onConfirmDiscard = () => {
      discardDialogRef.current.close()
      onDiscard()
    }

    return (
      <form>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={cardStyle}>
              <CardHeader color={headerColor}>
                {Logo && <Logo />}
                {title && <h4 className={classes.cardTitleWhite}>{title}</h4>}
                {subTitle && <p className={classes.cardCategoryWhite}>{subTitle}</p>}
              </CardHeader>
              <CardBody>{children}</CardBody>
              <CardFooter>
                {isSubmitting ? (
                  <Button color="primary" disabled>
                    {adjective(submitButtonText)}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!dirtyOnStart && !isDirty}
                    onClick={onSubmitForm}
                  >
                    {dirtyOnStart || isDirty ? submitButtonText : past(submitButtonText)}
                  </Button>
                )}
                <ConfirmDialog
                  title={submitConfirmTitle}
                  text={submitConfirmText}
                  onConfirm={onConfirmSubmit}
                  ref={submitDialogRef}
                />
                {discardButton && (dirtyOnStart || isDirty) && !isSubmitting && (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={dirtyOnStart ? false : !isDirty || isSubmitting}
                      onClick={onDiscardForm}
                    >
                      {discardButtonText}
                    </Button>
                    <ConfirmDialog
                      title={discardConfirmTitle}
                      confirmButtonText={discardButtonText}
                      text={discardConfirmText}
                      onConfirm={onConfirmDiscard}
                      ref={discardDialogRef}
                    />
                  </>
                )}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    )
  }
)

export default Form
